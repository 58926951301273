import React, { useState } from 'react';
import './App.css';
import Roll from './Roll';
import HowToPlay from "./HowToPlay";
import HttpsRedirect from 'react-https-redirect';

function App() {

  const [howTo, openHowTo] = useState(false)

  return (
    <HttpsRedirect>
      <div className="App">
        <header className="App-header">
          <a href={"https://www.deathroll.io"}>deathroll.io</a>
          <div className={"right"}>
            <ul>
              <li>
                <a onClick={() => {
                  openHowTo(true);
                }}>How To Play</a>
              </li>
            </ul>

          </div>
        </header>
        <main>
          <Roll/>
        </main>
        {howTo && (
          <div id={"modal-portal"} onClick={() => {
            openHowTo(false)
          }}>
            <HowToPlay close={() => {
              openHowTo(false);
            }}/>
          </div>
        )}
      </div>
    </HttpsRedirect>
  );
}

export default App;
