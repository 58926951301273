import React from 'react';

export default function HowToPlay({ close }) {

  return (
    <div id="how-to-play" className={"modal"} onClick={ev => ev.stopPropagation()}>

      <div className={"header"}>
        <h1>How to play</h1>
        <div className={"close"} onClick={() => {
          if (close) {
            close();
          }
        }}>✕</div>
      </div>

      <div className={"body"}>

        <ul>
          <li>
            Play with any number of people (2-6 players recommended)
          </li>
          <li>
            Take turns rolling
          </li>
          <li>
            First person to roll a 1 drinks!
          </li>
          <li>
            The current player rolls between 1 - #(previous persons roll) so the odds increase as you go.
          </li>
        </ul>
      </div>
    </div>
  )
}