import React, { useState, Fragment } from 'react';
import { CircularProgressbarWithChildren } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

const nextRoll = (max) => {

  return Math.ceil(Math.random() * max);
};

export default function Roll() {

  const [roll, setRoll] = useState(5000);
  const [prev, setRolls] = useState([]);

  let busted = roll === 1;
  return (
    <div className={"roll"}>
      <button className={`progress-bar ${busted ? 'red': ''}`} onClick={() => {
        if (busted) {
          setRoll(5000);
          setRolls([]);
        } else {
          let next = nextRoll(roll);

          setRoll(next);
          setRolls([next, ...prev]);
        }
      }}>

        <CircularProgressbarWithChildren value={100 - 100/roll}>


          <div className={"center"}>
            { busted ? (
              <Fragment>
                <h1 className={"red"}>Drink!</h1>
                <h2>Play again</h2>

              </Fragment>
            ): (
              <Fragment>
                <h1>Roll</h1>
                <h2>Odds: { (100/roll).toFixed(2) }%</h2>
                <h2>1 - {roll}</h2>
              </Fragment>
            )}

            <div className={"fill"}></div>
          </div>

        </CircularProgressbarWithChildren>

      </button>


      <div className={"previous"}>
        <div>Previous rolls</div>
        { prev.map((p, index) => {
          return <div key={p + ' ' + index}>Rolled {p}</div>
        })}
      </div>
    </div>
  )

}